@import '../../styles/customMediaQueries.css';

.termsMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 80px;
}

@media (--viewportLarge) {
  .termsMain {
    padding: 24px 240px;
  }
}
