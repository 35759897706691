@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 5px 0;
  text-align: left;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}
