@import '../../styles/customMediaQueries.css';

.privacyMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 80px;
}

@media (--viewportLarge) {
  .privacyMain {
    padding: 24px 240px;
  }
}
